import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Row,
  Form,
  FormGroup,
  FormSelect,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "shards-react"
import Svg from "react-inlinesvg"
import PageTitle from "../../../components/common/PageTitle"
import { userRoleConstants, hasAuthority } from "../../../_helpers"
import { useTranslation } from "react-i18next"

import { reportsActions } from ".."
import { surveyActions } from "../../surveys"
import { modalActions } from "../../modals"
import { authenticationActions } from "../../authentication"
import { mfiManagerActions } from "../../mfiManager"
import { alertActions } from "../../alert"
import Dashboard from "./impactdashboard"

import "./Overview.scss"

const barGraphBgSVG = require("../../../assets/images/icons/bar-graph-bg.svg")

export const ImpactReport = () => {
  const {
    profile: { mfi },
  } = useSelector((store) => store.authentication)
  let { currency } = mfi || {}
  const { items: mfiList } = useSelector((store) => store.mfis)
  const { assets } = useSelector((store) => store.surveys)
  const {
    impactReport,
    reports,
    dateInputs,
    hierarchyEntityIdInput,
    hierarchy,
    submittedBy,
  } = useSelector((store) => store.reports)
  const dispatch = useDispatch()
  const [selectedValue, setSelectedValue] = useState("")
  const [dropdown, setDropdown] = useState(false)
  const [dropdownApplication, setDropdownApplication] = useState(false)

  console.log(impactReport, "we are")

  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [selectedEntity, setSelectedEntity] = useState("")
  const [mfiId, setMfiId] = useState(null)
  const [activeTab, setActiveTab] = useState("social") // State for active tab

  const toggle = () => setDropdown(!dropdown)
  const toggleApplication = () => setDropdownApplication(!dropdownApplication)

  if (mfiId) {
    const selectedMfi = mfiList.filter((fi) => fi.id === Number(mfiId))
    currency = selectedMfi[0]?.currency
  }

  useEffect(() => {
    if (dateInputs.startDate) {
      setFrom(new Date(dateInputs.startDate))
      setTo(new Date(dateInputs.endDate))
    } else {
      setFrom(null)
      setTo(null)
    }
  }, [dateInputs])

  useEffect(() => {
    // populate entities/offices to be displayed
    if (hierarchy && hierarchyEntityIdInput) {
      hierarchy.map((item, i) => {
        const selectedHierarchyEntity = item.entities.filter(
          (entity, i) => entity.id === Number(hierarchyEntityIdInput)
        )
        if (selectedHierarchyEntity && selectedHierarchyEntity.length > 0) {
          setSelectedEntity(selectedHierarchyEntity[0].name)
        }
        return item
      })
    } else {
      setSelectedEntity("")
    }
  }, [hierarchy, hierarchyEntityIdInput])

  useEffect(() => {
    mfiId && dispatch(surveyActions.getSurveys(mfiId))
  }, [mfiId, dispatch])

  useEffect(() => {
    dispatch(authenticationActions.getProfile())
    if (
      hasAuthority(userRoleConstants.FA_ANALYST) ||
      hasAuthority(userRoleConstants.FA_ADMIN) ||
      hasAuthority(userRoleConstants.MFI_SUPERVISOR) ||
      hasAuthority(userRoleConstants.MFI_ADMIN)
    ) {
      dispatch(mfiManagerActions.getAllMFIs())
    }
  }, [dispatch])

  useEffect(() => {
    // set first questionare as the selected one
    if (assets && assets.length > 0) {
      setSelectedValue(`${assets[0].uid}:${assets[0].versionId}`)
    }
  }, [assets])

  const { t } = useTranslation()

  useEffect(() => {
    if (selectedValue && !mfiId) {
      let payload = {
        surveyUuid: selectedValue,
      }
      if (hierarchyEntityIdInput) {
        payload.hierarchyEntityId = Number(hierarchyEntityIdInput)
      }

      if (from || to) {
        const start = from.toLocaleDateString().split("/")
        const end = to.toLocaleDateString().split("/")
        payload.startDate = `${start[2]}-${start[1]}-${start[0]}`
        payload.endDate = `${end[2]}-${end[1]}-${end[0]}`
      }

      dispatch(reportsActions.filterReports(payload))
    }
  }, [selectedValue, dispatch]) // eslint-disable-line

  useEffect(() => {
    if (mfiId) {
      let payload = {
        surveyUuid: selectedValue,
      }

      if (
        hasAuthority(userRoleConstants.FA_ANALYST) ||
        hasAuthority(userRoleConstants.FA_ADMIN)
      ) {
        payload.mfiId = Number(mfiId)
      }

      if (from || to) {
        const start = from.toLocaleDateString().split("/")
        const end = to.toLocaleDateString().split("/")
        payload.startDate = `${start[2]}-${start[1]}-${start[0]}`
        payload.endDate = `${end[2]}-${end[1]}-${end[0]}`
      }
      dispatch(reportsActions.resetHierarchyInputs())
      dispatch(reportsActions.filterReports(payload))
    }
  }, [selectedValue, dispatch, mfiId]) // eslint-disable-line

  useEffect(() => {
    Array.from(document.getElementsByClassName("report-card")).forEach(
      (item, i) => {
        animateValue(
          `value${i}`,
          0,
          Object.values(reports)[i] || 0,
          500 + i * 500
        )
      }
    )
  }, [reports])

  const animateValue = (id, start, end, duration) => {
    if (end !== 0) {
      let obj = document.getElementById(id)
      if (typeof end === "string") {
        obj.innerHTML = end
        return
      }

      let range = end - start
      let current = start
      let increment = end > start ? 1 : -1
      let stepTime = Math.abs(Math.floor(duration / range))
      if (end > 600) {
        stepTime = Math.abs(Math.floor(duration / 600))
        let timer = setInterval(() => {
          current += increment
          obj.innerHTML = current
          if (current === 600) {
            clearInterval(timer)
            obj.innerHTML = end
          }
        }, stepTime)
      } else {
        let timer = setInterval(() => {
          current += increment
          obj.innerHTML = current
          if (current === end) {
            clearInterval(timer)
          }
        }, stepTime)
      }
    }
  }

  const userFilter = () => dispatch(modalActions.userFilter())

  const handler = (e) => {
    const { value } = e.target
    setSelectedValue(value)
  }

  const filteredKeys = [
    "numberOfApplications",
    // "femaleApplicationsPercentage",
    // "totalNumberOfYouthApplicants",
    "creditTurnAroundTime",
  ]

  const cards = []
  let i = 0

  for (let [key, value] of Object.entries(reports)) {
    if (filteredKeys.includes(key)) {
      let valueCurrency = ""
      if (
        key === "valueOfApplicationsPendingDisbursement" ||
        key === "valueOfDisbursedLoans" ||
        key === "valueOfRefusedDeclinedLoans"
      ) {
        valueCurrency = `(${currency})`
      }

      cards.push(
        <div className="report-card" key={key}>
          <div className="report-card-title text-center">
            {`${t(`module.report.cards.${key}`)} ${valueCurrency}`}
          </div>
          <div id={`value${i}`} className="report-card-value">
            {value || 0}
          </div>
          <Svg className="text-center d-inline-block" src={barGraphBgSVG} />
        </div>
      )
      i++
    }
  }

  const handleExport = (format) => {
    let payload = {
      format: format,
      surveyUuid: selectedValue,
    }

    if (
      hasAuthority(userRoleConstants.FA_ANALYST) ||
      hasAuthority(userRoleConstants.FA_ADMIN)
    ) {
      if (mfiId) {
        payload.mfiId = Number(mfiId)
      } else {
        dispatch(alertActions.error(t("module.report.selectMFIFirst")))
        return
      }
    }

    if (dateInputs.endDate) {
      payload.endDate = dateInputs.endDate
    }
    if (dateInputs.startDate) {
      payload.startDate = dateInputs.startDate
    }
    dispatch(reportsActions.exportReports(payload))
  }

  const handleApplicationExport = (format) => {
    let payload = {
      format: format,
      surveyUuid: selectedValue,
    }

    if (
      hasAuthority(userRoleConstants.FA_ANALYST) ||
      hasAuthority(userRoleConstants.FA_ADMIN)
    ) {
      if (mfiId) {
        payload.mfiId = Number(mfiId)
      } else {
        dispatch(alertActions.error(t("module.report.selectMFIFirst")))
        return
      }
    }

    if (dateInputs.endDate) {
      payload.endDate = dateInputs.endDate
    }
    if (dateInputs.startDate) {
      payload.startDate = dateInputs.startDate
    }
    dispatch(reportsActions.exportApplicationsReports(payload))
  }

  useEffect(() => {
    dispatch(
      reportsActions.fetchImpactReport({
        surveyUuid: selectedValue,
        mfiId,
        from,
        to,
      })
    )
  }, [selectedValue, dispatch])

  const impactReportData = useSelector((state) => state.reports.impactReport)

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={t("Impact")}
          subtitle={t("module.report.dashboard")}
          className="text-sm-left mb-3"
        />
      </Row>

      {/* Tab Navigation */}
      {/* <Row className="mb-4">
        <Button
          color={activeTab === "social" ? "primary" : "secondary"}
          onClick={() => setActiveTab("social")}
          className="mr-2"
        >
          Social
        </Button>
        <Button
          color={activeTab === "financial" ? "primary" : "secondary"}
          onClick={() => setActiveTab("financial")}
        >
          Financial
        </Button>
      </Row> */}

      {/* Social Tab Content */}
      {activeTab === "social" && (
        <>
          <Form className="shadow-none bg-transparent m-0">
            <FormGroup className="flex items-center mb-0 select-questionaire">
              <FormSelect
                name="uid"
                size="sm"
                className="mr-auto w-auto"
                onChange={handler}
                value={selectedValue}
              >
                <option key={-1} value="">
                  {t("module.report.selectQuestionnaire")}
                </option>
                {assets &&
                  assets.map((questionnaire, idx) => {
                    const { uid, name, versionId } = questionnaire
                    return (
                      <option key={idx} value={`${uid}:${versionId}`}>
                        {name}
                      </option>
                    )
                  })}
              </FormSelect>
              {(hasAuthority(userRoleConstants.FA_ANALYST) ||
                hasAuthority(userRoleConstants.FA_ADMIN)) && (
                <>
                  &nbsp;&nbsp;
                  <FormSelect
                    name="mfiName"
                    size="sm"
                    className={`mr-auto w-auto ${!mfiId && "select-error"}`}
                    value={mfiId}
                    onChange={(e) => setMfiId(e.currentTarget.value)}
                  >
                    <option key={-1} value="">
                      {t("module.scoreLogic.view.scoreLogic.titles.selectMFI")}
                    </option>
                    {mfiList.map((item, idx) => (
                      <option key={idx} value={item.id}>
                        {item.mfiName}
                      </option>
                    ))}
                  </FormSelect>
                </>
              )}
              &nbsp;&nbsp;
              <Button
                theme="secondary"
                className="mx-2 d-table ml-auto btn btn-filter"
                onClick={() =>
                  dispatch(
                    modalActions.reportsOfficeFilter({
                      surveyUuid: selectedValue,
                    })
                  )
                }
              >
                {t("module.report.filterByOffice")}
                {selectedEntity && `: ${selectedEntity}`}
              </Button>
              &nbsp;&nbsp;
              <Button
                theme="secondary"
                className="mx-2 d-table ml-auto btn btn-user-filter"
                onClick={() =>
                  dispatch(
                    modalActions.reportsOfficeFilter({
                      surveyUuid: selectedValue,
                      byOfficer: true,
                    })
                  )
                }
              >
                {t("module.report.filterByLoanOfficer")}
                {submittedBy && `: ${submittedBy}`}
              </Button>
              &nbsp;&nbsp;
              <Button
                theme="secondary"
                className="mx-2 d-table ml-auto btn btn-filter"
                onClick={() =>
                  dispatch(modalActions.reportsDateFilter(selectedValue))
                }
              >
                {t("module.report.filterByDate")}
              </Button>
              <Dropdown open={dropdown} size="lg" toggle={toggle}>
                <DropdownToggle className="btn-export-reports" caret>
                  {t("module.report.exportReport")}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onMouseDown={() => handleExport("csv")}>
                    {t("module.report.csv")}
                  </DropdownItem>
                  <DropdownItem onMouseDown={() => handleExport("xlsx")}>
                    {t("module.report.excel")}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              &nbsp;&nbsp;
              <Dropdown
                open={dropdownApplication}
                size="lg"
                toggle={toggleApplication}
              >
                <DropdownToggle className="btn-export-reports" caret>
                  {t("module.report.exportApplications")}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onMouseDown={() => handleApplicationExport("csv")}
                  >
                    {t("module.report.csv")}
                  </DropdownItem>
                  <DropdownItem
                    onMouseDown={() => handleApplicationExport("xlsx")}
                  >
                    {t("module.report.excel")}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Form>
          <div className="graph-container">{cards}</div>
          {cards.length === 0 && (
            <div className="cards-empty">
              <div>{t("module.report.noRecordsToShow")}</div>
              <Svg className="text-center d-inline-block" src={barGraphBgSVG} />
            </div>
          )}
          <div>
            {cards.length === 0 ? (
              <div></div>
            ) : (
              <>
                <div>
                  {/* Render Dashboard component with the fetched data */}
                  <Dashboard
                    loansPerStaffData={impactReportData.loansPerStaff}
                    loanValuePerStaffData={impactReportData.valueLoansPerStaff}
                    loanValuesPerBranchData={
                      impactReportData.valueLoansPerBranch
                    }
                    loansPerBranchData={impactReportData.numberLoansPerBranch}
                    workflowStatusData={impactReportData.countPerWorkflowStatus}
                    currencyData={currency}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}

      {/* Financial Tab Content */}
      {/* {activeTab === "financial" && (
        <>
          <Form className="shadow-none bg-transparent m-0">
            <FormGroup className="flex items-center mb-0 select-questionaire">
              <FormSelect
                name="uid"
                size="sm"
                className="mr-auto w-auto"
                onChange={handler}
                value={selectedValue}
              >
                <option key={-1} value="">
                  {t("module.report.selectQuestionnaire")}
                </option>
                {assets &&
                  assets.map((questionnaire, idx) => {
                    const { uid, name, versionId } = questionnaire
                    return (
                      <option key={idx} value={`${uid}:${versionId}`}>
                        {name}
                      </option>
                    )
                  })}
              </FormSelect>
              {(hasAuthority(userRoleConstants.FA_ANALYST) ||
                hasAuthority(userRoleConstants.FA_ADMIN)) && (
                <>
                  &nbsp;&nbsp;
                  <FormSelect
                    name="mfiName"
                    size="sm"
                    className={`mr-auto w-auto ${!mfiId && "select-error"}`}
                    value={mfiId}
                    onChange={(e) => setMfiId(e.currentTarget.value)}
                  >
                    <option key={-1} value="">
                      {t("module.scoreLogic.view.scoreLogic.titles.selectMFI")}
                    </option>
                    {mfiList.map((item, idx) => (
                      <option key={idx} value={item.id}>
                        {item.mfiName}
                      </option>
                    ))}
                  </FormSelect>
                </>
              )}
              &nbsp;&nbsp;
              <Button
                theme="secondary"
                className="mx-2 d-table ml-auto btn btn-filter"
                onClick={() =>
                  dispatch(
                    modalActions.reportsOfficeFilter({
                      surveyUuid: selectedValue,
                    })
                  )
                }
              >
                {t("module.report.filterByOffice")}
                {selectedEntity && `: ${selectedEntity}`}
              </Button>
              &nbsp;&nbsp;
              <Button
                theme="secondary"
                className="mx-2 d-table ml-auto btn btn-user-filter"
                onClick={() =>
                  dispatch(
                    modalActions.reportsOfficeFilter({
                      surveyUuid: selectedValue,
                      byOfficer: true,
                    })
                  )
                }
              >
                {t("module.report.filterByLoanOfficer")}
                {submittedBy && `: ${submittedBy}`}
              </Button>
              &nbsp;&nbsp;
              <Button
                theme="secondary"
                className="mx-2 d-table ml-auto btn btn-filter"
                onClick={() =>
                  dispatch(modalActions.reportsDateFilter(selectedValue))
                }
              >
                {t("module.report.filterByDate")}
              </Button>
              <Dropdown open={dropdown} size="lg" toggle={toggle}>
                <DropdownToggle className="btn-export-reports" caret>
                  {t("module.report.exportReport")}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onMouseDown={() => handleExport("csv")}>
                    {t("module.report.csv")}
                  </DropdownItem>
                  <DropdownItem onMouseDown={() => handleExport("xlsx")}>
                    {t("module.report.excel")}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              &nbsp;&nbsp;
              <Dropdown
                open={dropdownApplication}
                size="lg"
                toggle={toggleApplication}
              >
                <DropdownToggle className="btn-export-reports" caret>
                  {t("module.report.exportApplications")}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onMouseDown={() => handleApplicationExport("csv")}
                  >
                    {t("module.report.csv")}
                  </DropdownItem>
                  <DropdownItem
                    onMouseDown={() => handleApplicationExport("xlsx")}
                  >
                    {t("module.report.excel")}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Form>
          <div className="graph-container">{cards}</div>
          {cards.length === 0 && (
            <div className="cards-empty">
              <div>{t("module.report.noRecordsToShow")}</div>
              <Svg className="text-center d-inline-block" src={barGraphBgSVG} />
            </div>
          )}
          <div className="chart-row">
            <div className="chart-container">
              <canvas ref={chartRefStatus}></canvas>
            </div>
            <div className="chart-container">
              <canvas ref={chartRefStaff}></canvas>
            </div>
            <div className="chart-container">
              <canvas ref={chartRef3}></canvas>
            </div>
          </div>
        </>
      )} */}
    </Container>
  )
}
