import React, { useState, useEffect } from "react"
import { Container, Row } from "shards-react"
import { AgGridReact } from "ag-grid-react"
import { Link, useLocation } from "react-router-dom"
import { startCase } from "lodash"
import { Typography, CircularProgress } from "@material-ui/core"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import { useSelector, useDispatch } from "react-redux"
import PageTitle from "../../../components/common/PageTitle"
import { applicationActions } from "../_actions"
import { ImageCellRenderer } from "./ImageCellRenderer"
import "./Members.scss"

export const GroupMembers = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const { state: { groupName = {}, groupMembers = [], groupID } = {} } =
    location || {}

  const { entityStatusList = {}, loading: entityStatusLoading } = useSelector(
    (state) => state.applications
  )

  useEffect(() => {
    const externalClientIds = groupMembers.map((member) => member.nrcNo)
    if (externalClientIds.length > 0) {
      dispatch(applicationActions.fetchEntityStatus(externalClientIds))
    }
  }, [dispatch, groupMembers])

  const statusRenderer = (params) => {
    const externalId = params.data.nrcNo
    const status = entityStatusList?.statusData?.[externalId] || "Pending"

    return (
      <div className="actions-menu">
        {entityStatusLoading ? <CircularProgress size={24} /> : status}
      </div>
    )
  }

  const viewChecklistRenderer = () => (
    <Link to={{ pathname: "/document-checklist", state: { groupID } }}>
      View Checklist
    </Link>
  )

  const columnDefs = groupMembers.length
    ? Object.keys(groupMembers[0]).map((key) => {
        if (key === "clientImage") {
          return {}
        } else {
          return {
            headerName: startCase(key),
            field: key,
            sortable: true,
            filter: true,
          }
        }
      })
    : []

  columnDefs.push({
    headerName: "Status",
    field: "status",
    cellRenderer: statusRenderer,
    pinned: "right",
  })

  return (
    <Container maxWidth="xl" fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-4 flex">
        <PageTitle sm="4" title={"Group Members"} className="text-sm-left" />
      </Row>
      <Row noGutters className="page-header pt-4 flex">
        <Typography className="mr-2 name-semibold">Group Name: </Typography>
        <Typography className="mr-3" color="secondary">
          {groupName}
        </Typography>
      </Row>
      <div
        className="ag-theme-alpine"
        style={{ height: "600px", width: "100%", marginTop: "20px" }}
      >
        <AgGridReact
          rowData={groupMembers}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={30}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          stopEditingWhenGridLosesFocus={true}
        />
      </div>
    </Container>
  )
}

export default GroupMembers
